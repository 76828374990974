/* @media screen and (max-width: 368px) { */
/* TODO fix this after responsive */
@media screen and (max-width: 168px) {

    .sidebar-footer {
      position: -webkit-sticky;
      bottom: 0;
      left: 0;
      right: 0;
      height: 43px;
      background-color: var(--sidebar-footer-bg);
      box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.1);
    }

    .mobile-toolbar {
      /* position: fixed !important;
      bottom: 0px !important;
      left: 0px !important;
      padding-left: 0px !important; */
    }

    .pdf-navigation {
      display: flex;
      justify-content: space-between;
      height: 40px;
      border-top: 1px solid var(--border-color);
      padding: 5px;
      float: left;
      width: 46.5%;
    }

    .corporation {
      height: 40px;
      padding: 2px 5px;
      border-top: 1px solid var(--border-dark-color);
      width: 46.5%;
      float: left;
    }

    .sidebar.open {
      margin-left: 0px !important;
      position: absolute;
      z-index: 10;
      box-shadow: 8px 0 8px rgba(0, 0, 0, 0.7);
      bottom: 0;
    }

    .pdf-page-list {
      padding: 5px 5px 0;
      cursor: pointer;
      /* 0 24px 0  */
      margin: 0 !important;
    }

    .qhideable-top-margin {
      padding-top: 50px;
    }

    .pdf-page {
      width: 100%;
      position: relative;
      margin-bottom: 30px;
      margin-top: -30px;
      float: left;
    }

    .sidebar {
      position: relative;
      width: 100%;
      height: auto;
      background: var(--sidebar-bg);
      transition: all 0.3s;
      overflow: hidden;
    }

    .pdf-thumbnail-list {
      height: 32vh;
      overflow-y: auto;
      padding: 0;
    }

    .pdf-thumbnail {
      margin: 0 1%;
      transition: all 0.3s;
      float: left;
      height: 22vh;
      width: 22.5%;
    }

    .canvas-container canvas {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.75);
      /* width: 100% !important; */
      /* height: auto !important; */
    }

    .canvas-container {
      border: 1px solid #0d3349;
      width: 100% !important;
      height: auto !important;
      top: 30px;

    }

    .bookmark {
      position: absolute;
      right: -10px;
      top: 58px;
      width: 54px;
      height: 54px;
      border: 1px solid #1D1E20;
      border-radius: 50px;
      padding: 14px;
      text-align: center;
      overflow: hidden;
      z-index: 1;
      cursor: pointer;
      /* outline: 0; */
      outline: none !important;
    }

    /* .pdf-page-list {
      padding: 0 24px 0;
    } */
    .interactive-object-area.h5p-answer-object.style2 {
      /*top: 330px !important;
      left: 185px !important;*/
    }

    .interactive-object-area.text-object.style2 {
      top: 240px !important;
      left: 170px !important;
    }

    .interactive-object-area.audio-object.style2 {
      top: 240px !important;
      left: 260px !important;
    }

    .toolbar-items .button {
      width: 30px;
      height: 34px;
    }

    .login-selection {
      position: absolute;
    }

    .library-selection {
      top: 10px;
      right: 0px;
      margin-top: 0px !important;
      margin-right: 5px !important;
    }

    /* .select-area{
      position: fixed;
      right: 50px;
      top: 50px;
    } */
  }