@media screen and (min-width: 1024px) {
    .sidebar {
        margin-left: -200px !important;
        position: absolute;
        z-index: 10;
        box-shadow: none;
    }

    .searchbar {
        margin-right: -200px !important;
        position: absolute;
        right: 0;
        z-index: 100;
        box-shadow: none;
    }

    .sidebar.hide {
        margin-left: -200px !important;
        position: absolute;
        z-index: 10;
        box-shadow: none;
    }

    .searchbar.hide {
        overflow-y: auto;
        margin-right: -200px !important;
        position: absolute;
        z-index: 10;
        box-shadow: none;
        visibility: hidden;
        display: none;
    }

    .sidebar.open {
        margin-left: 0px !important;
        position: absolute;
        z-index: 10;
        box-shadow: 8px 0 8px rgba(0, 0, 0, 0.7);
    }

    .searchbar.open {
        margin-right: 0px !important;
        position: absolute;
        z-index: 100;
        box-shadow: 8px 0 8px rgba(0, 0, 0, 0.7);
        display: unset;
    }

    .collapse-sidebar {
        position: fixed;
        bottom: 0px;
        left: -3px;
        transform: rotate(180deg);
        z-index: 1;
        border-color: var(--border-color);
        background-color: var(--sidebar-footer-bg);
    }

    .collapse-searchbar {
        position: fixed;
        bottom: 0px;
        right: 0px;
        transform: rotate(0deg);
        z-index: 1;
        border-color: var(--border-light-color);
        background-color: var(--sidebar-footer-bg);
    }

    .sidebar.open .collapse-sidebar {
        position: static;
        transform: rotate(0deg);
    }

    .searchbar.open .collapse-searchbar {
        position: absolute;
        transform: rotate(180deg);
        right: auto;
        left: 3px;
    }

    .pdf-page-list {
        padding: 25px 25px 0 25px;
        cursor: pointer;
    }

    .qhideable-top-margin {
        padding-top: 50px;
    }

    .pdf-page figure:not(:last-child) {
        margin: 0 0 35px;
    }

    .toolbar-wrapper {
        left: calc(100% - 394px);
        bottom: 48vh;
    }

    .toolbar-wrapper-alternative {
        left: calc(100% - 464px);
        bottom: 48vh;
    }

    .toolbar-init {
        left: calc(100% - 264px);
    }

    .toolbar-wrapper-change-left {
        left: calc(83% - 303px);
    }

    .toolbar-intro-sudo-class {
        height: 0px;
        width: 0px;
        left: calc(100% - 155px);
        bottom: 48vh;
        position: absolute;
        zIndex: 10;
    }

    .sidebar-footer {
        /* position: absolute; */
        /* bottom: 0; */
        /* left: 0;
        right: 0; */
        /* height: 122px; */
        padding-bottom: 10px;
        margin-bottom: 1px;
        /* background-color: var(--sidebar-footer-bg); */
        /* box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.1); */
    }
}