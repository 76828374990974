
@media screen and (max-width: 765px) {
  .toolbar-intro-sudo-class {
    height: 0px;
    width: 0px;
    left: calc(100% - 155px);
    bottom: 48vh;
    position: absolute;
    zIndex: 10;
  }

  .toolbar-wrapper {
    bottom: 55vh;
    left: calc(60% - 284px);
  }
  .toolbar-wrapper-alternative {
    bottom: 55vh;
    left: calc(60% - 354px);
  }
  .toolbar-init {
    left: calc(60% - 154px);
  }


}