.interactive-object-area.styleZoom .icon-only svg circle {
  stroke: none !important;
}

.interactive-object-area.styleZoom .object-icon {
  box-shadow: 0 0 0 rgba(31, 25, 13, 0.5);
  animation: styleZoom 2s var(--animationCount);
  transform: translateZ(0);
  /* only needed if no CSS-3d commands are used to toggle GPU rendering */
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  backface-visibility: hidden;
  perspective: 1000;
}

@keyframes styleZoom {
  0% {
    box-shadow: 0 0 0 0 rgb(255, 255, 255);
  }

  50% {
    box-shadow: 0 0 0 5px rgba(106, 76, 147, 0.25);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(106, 76, 147, 0.5);
  }
}




.interactive-object-area.styleZoom .object-icon svg circle {
  fill: none;
  stroke: #050303;
}

.interactive-object-area.styleZoom .object-icon svg path {
  fill: rgba(106, 76, 147, 0.5);
}

