.winbox.modern {
  background: linear-gradient(90deg, #ff00f0, #0050ff);
  border-radius: 12px 12px 0 0;
  animation: popup 0.3s cubic-bezier(0.3, 1, 0.3, 1) backwards;
}
.winbox.modern:not(.min):not(.focus) {
  background: #666;
}
@media (hover: hover) {
  .winbox.modern.min:not(:hover) {
    background: #666;
  }
  .winbox.modern .wb-icon * {
    opacity: 0.65;
  }
  .winbox.modern .wb-icon *:hover {
    opacity: 1;
  }
}
@media (hover: none) {
  .winbox.modern.min {
    background: #666;
  }
}
.winbox.modern.max {
  border-radius: 0;
}
.winbox.modern .wb-title {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
}
.winbox.modern .wb-body {
  margin: 4px;
  color: #fff;
  background: #131820;
}
.winbox.modern .wb-body::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.winbox.modern .wb-body::-webkit-scrollbar-track {
  background: transparent;
}
.winbox.modern .wb-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #263040;
}
.winbox.modern .wb-body::-webkit-scrollbar-thumb:window-inactive {
  background: #181f2a;
}
.winbox.modern .wb-body::-webkit-scrollbar-corner {
  background: transparent;
}
.winbox-header-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.display-flex {
  display: flex;
}
.winbox-header-button {
  border: none;
  background: transparent;
  line-height: initial;
  color: var(--icons-filter);
  padding-right: 20px;
  margin-right: 12px;

  /* pointer-events: all !important; */
}
.wb-min{
    margin-right: 10px;
}
.wb-max{
    margin-right: 10px;
}
.winbox-header-button .icon {
  transform: scale(5);
  padding-bottom: 3px;
}
@keyframes popup {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.winbox.min {
  left: 140px !important;
  /* width: 100px !important; */
}

/* .winbox:not(.max) { */
/* height: auto !important; */
/* } */
/* .winbox:not(.max) .wb-body{
    position:relative !important;
    contain: initial !important;
    overflow: scroll !important;
} */
.wb-icon {
  display: flex;
  flex-wrap: nowrap;
  align-content: space-around;
  align-items: center;
}
.player-wrapper {
  position: unset !important;
}
.winbox.min .newelmentcontainer {
 display: none ;
}
.newelmentcontainer {
  width: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content:flex-end;
  margin-top: 14px;
  margin-right: 90px;
}
.customeyellow {
  color: var(--yellow);
}

/* .wb-max {
background-image: url("./max.svg");
background-size: 17px auto;
}
.wb-close {
background-image: url("./close.svg");
background-size: 15px auto;
}
.wb-full {
background-image: url("./full.svg");
background-size: 16px auto;
} */
/* .wb-header{
    background-color: var(--gray-dark);
    border: 1px solid var(--border-light-color);
}
.my-theme-light{
    background-color: var(--light);
    border: 1px solid var(--border-light-color);
}

.my-theme-dark-blue{
    background-color: var(--sidebar-bg);
    border: 1px solid var(--border-light-color);
}
.my-theme-green{
    background-color: var(--sidebar-bg);
    border: 1px solid var(--border-light-color);
}
.my-theme-purple{
    background-color: var(--purple);
    border: 1px solid var(--border-light-color);
} */
