@-webkit-keyframes spinAround {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes spinAround {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

.switch[type="checkbox"] {
    outline: 0;
    user-select: none;
    display: inline-block;
    position: absolute;
    opacity: 0
}

.switch[type="checkbox"]:focus+label::before,.switch[type="checkbox"]:focus+label:before,.switch[type="checkbox"]:focus+label::after,.switch[type="checkbox"]:focus+label:after {
    outline: 0px dotted #b5b5b5
}

.switch[type="checkbox"][disabled] {
    cursor: not-allowed
}

.switch[type="checkbox"][disabled]+label {
    opacity: .5
}

.switch[type="checkbox"][disabled]+label::before,.switch[type="checkbox"][disabled]+label:before {
    opacity: .5
}

.switch[type="checkbox"][disabled]+label::after,.switch[type="checkbox"][disabled]+label:after {
    opacity: .5
}

.switch[type="checkbox"][disabled]+label:hover {
    cursor: not-allowed
}

.switch[type="checkbox"]+label {
    position: relative;
    display: initial;
    font-size: 1rem;
    line-height: initial;
    padding-left: 3.5rem;
    padding-top: .2rem;
    cursor: pointer
}

.switch[type="checkbox"]+label::before,.switch[type="checkbox"]+label:before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 3rem;
    height: 1.5rem;
    border: .1rem solid transparent;
    border-radius: 4px;
    background: #b5b5b5;
    content: ""
}

.switch[type="checkbox"]+label::after,.switch[type="checkbox"]+label:after {
    display: block;
    position: absolute;
    top: .25rem;
    left: .25rem;
    width: 1rem;
    height: 1rem;
    transform: translate3d(0, 0, 0);
    border-radius: 4px;
    background: #fff;
    transition: all .25s ease-out;
    content: ""
}

.switch[type="checkbox"].is-rtl+label {
    padding-left: 0;
    padding-right: 3.5rem
}

.switch[type="checkbox"].is-rtl+label::before,.switch[type="checkbox"].is-rtl+label:before {
    left: auto;
    right: 0
}

.switch[type="checkbox"].is-rtl+label::after,.switch[type="checkbox"].is-rtl+label:after {
    left: auto;
    right: .25rem
}

.switch[type="checkbox"]:checked+label::before,.switch[type="checkbox"]:checked+label:before {
    background: #00d1b2
}

.switch[type="checkbox"]:checked+label::after {
    left: 1.625rem
}

.switch[type="checkbox"]:checked.is-rtl+label::after,.switch[type="checkbox"]:checked.is-rtl+label:after {
    left: auto;
    right: 1.625rem
}

.switch[type="checkbox"].is-outlined+label::before,.switch[type="checkbox"].is-outlined+label:before {
    background-color: transparent;
    border-color: #b5b5b5
}

.switch[type="checkbox"].is-outlined+label::after,.switch[type="checkbox"].is-outlined+label:after {
    background: #b5b5b5
}

.switch[type="checkbox"].is-outlined:checked+label::before,.switch[type="checkbox"].is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #00d1b2
}

.switch[type="checkbox"].is-outlined:checked+label::after,.switch[type="checkbox"].is-outlined:checked+label:after {
    background: #00d1b2
}

.switch[type="checkbox"].is-thin+label::before,.switch[type="checkbox"].is-thin+label:before {
    top: .5454545455rem;
    height: .375rem
}

.switch[type="checkbox"].is-thin+label::after,.switch[type="checkbox"].is-thin+label:after {
    box-shadow: 0px 0px 3px #7a7a7a
}

.switch[type="checkbox"].is-rounded+label::before,.switch[type="checkbox"].is-rounded+label:before {
    border-radius: 24px
}

.switch[type="checkbox"].is-rounded+label::after,.switch[type="checkbox"].is-rounded+label:after {
    border-radius: 50%
}

.switch[type="checkbox"].is-small+label {
    position: relative;
    display: initial;
    font-size: .75rem;
    line-height: initial;
    padding-left: 2.75rem;
    padding-top: .05rem;
    cursor: pointer
}

.switch[type="checkbox"].is-small+label::before,.switch[type="checkbox"].is-small+label:before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 2.25rem;
    height: 1.125rem;
    border: .1rem solid transparent;
    border-radius: 4px;
    background: #b5b5b5;
    content: ""
}

.switch[type="checkbox"].is-small+label::after,.switch[type="checkbox"].is-small+label:after {
    display: block;
    position: absolute;
    top: .25rem;
    left: .25rem;
    width: .625rem;
    height: .625rem;
    transform: translate3d(0, 0, 0);
    border-radius: 4px;
    background: #fff;
    transition: all .25s ease-out;
    content: ""
}

.switch[type="checkbox"].is-small.is-rtl+label {
    padding-left: 0;
    padding-right: 2.75rem
}

.switch[type="checkbox"].is-small.is-rtl+label::before,.switch[type="checkbox"].is-small.is-rtl+label:before {
    left: auto;
    right: 0
}

.switch[type="checkbox"].is-small.is-rtl+label::after,.switch[type="checkbox"].is-small.is-rtl+label:after {
    left: auto;
    right: .25rem
}

.switch[type="checkbox"].is-small:checked+label::before,.switch[type="checkbox"].is-small:checked+label:before {
    background: #00d1b2
}

.switch[type="checkbox"].is-small:checked+label::after {
    left: 1.25rem
}

.switch[type="checkbox"].is-small:checked.is-rtl+label::after,.switch[type="checkbox"].is-small:checked.is-rtl+label:after {
    left: auto;
    right: 1.25rem
}

.switch[type="checkbox"].is-small.is-outlined+label::before,.switch[type="checkbox"].is-small.is-outlined+label:before {
    background-color: transparent;
    border-color: #b5b5b5
}

.switch[type="checkbox"].is-small.is-outlined+label::after,.switch[type="checkbox"].is-small.is-outlined+label:after {
    background: #b5b5b5
}

.switch[type="checkbox"].is-small.is-outlined:checked+label::before,.switch[type="checkbox"].is-small.is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #00d1b2
}

.switch[type="checkbox"].is-small.is-outlined:checked+label::after,.switch[type="checkbox"].is-small.is-outlined:checked+label:after {
    background: #00d1b2
}

.switch[type="checkbox"].is-small.is-thin+label::before,.switch[type="checkbox"].is-small.is-thin+label:before {
    top: .4090909091rem;
    height: .28125rem
}

.switch[type="checkbox"].is-small.is-thin+label::after,.switch[type="checkbox"].is-small.is-thin+label:after {
    box-shadow: 0px 0px 3px #7a7a7a
}

.switch[type="checkbox"].is-small.is-rounded+label::before,.switch[type="checkbox"].is-small.is-rounded+label:before {
    border-radius: 24px
}

.switch[type="checkbox"].is-small.is-rounded+label::after,.switch[type="checkbox"].is-small.is-rounded+label:after {
    border-radius: 50%
}

.switch[type="checkbox"].is-medium+label {
    position: relative;
    display: initial;
    font-size: 1.25rem;
    line-height: initial;
    padding-left: 4.25rem;
    padding-top: .2rem;
    cursor: pointer
}

.switch[type="checkbox"].is-medium+label::before,.switch[type="checkbox"].is-medium+label:before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 3.75rem;
    height: 1.875rem;
    border: .1rem solid transparent;
    border-radius: 4px;
    background: #b5b5b5;
    content: ""
}

.switch[type="checkbox"].is-medium+label::after,.switch[type="checkbox"].is-medium+label:after {
    display: block;
    position: absolute;
    top: .25rem;
    left: .25rem;
    width: 1.375rem;
    height: 1.375rem;
    transform: translate3d(0, 0, 0);
    border-radius: 4px;
    background: #fff;
    transition: all .25s ease-out;
    content: ""
}

.switch[type="checkbox"].is-medium.is-rtl+label {
    padding-left: 0;
    padding-right: 4.25rem
}

.switch[type="checkbox"].is-medium.is-rtl+label::before,.switch[type="checkbox"].is-medium.is-rtl+label:before {
    left: auto;
    right: 0
}

.switch[type="checkbox"].is-medium.is-rtl+label::after,.switch[type="checkbox"].is-medium.is-rtl+label:after {
    left: auto;
    right: .25rem
}

.switch[type="checkbox"].is-medium:checked+label::before,.switch[type="checkbox"].is-medium:checked+label:before {
    background: #00d1b2
}

.switch[type="checkbox"].is-medium:checked+label::after {
    left: 2rem
}

.switch[type="checkbox"].is-medium:checked.is-rtl+label::after,.switch[type="checkbox"].is-medium:checked.is-rtl+label:after {
    left: auto;
    right: 2rem
}

.switch[type="checkbox"].is-medium.is-outlined+label::before,.switch[type="checkbox"].is-medium.is-outlined+label:before {
    background-color: transparent;
    border-color: #b5b5b5
}

.switch[type="checkbox"].is-medium.is-outlined+label::after,.switch[type="checkbox"].is-medium.is-outlined+label:after {
    background: #b5b5b5
}

.switch[type="checkbox"].is-medium.is-outlined:checked+label::before,.switch[type="checkbox"].is-medium.is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #00d1b2
}

.switch[type="checkbox"].is-medium.is-outlined:checked+label::after,.switch[type="checkbox"].is-medium.is-outlined:checked+label:after {
    background: #00d1b2
}

.switch[type="checkbox"].is-medium.is-thin+label::before,.switch[type="checkbox"].is-medium.is-thin+label:before {
    top: .6818181818rem;
    height: .46875rem
}

.switch[type="checkbox"].is-medium.is-thin+label::after,.switch[type="checkbox"].is-medium.is-thin+label:after {
    box-shadow: 0px 0px 3px #7a7a7a
}

.switch[type="checkbox"].is-medium.is-rounded+label::before,.switch[type="checkbox"].is-medium.is-rounded+label:before {
    border-radius: 24px
}

.switch[type="checkbox"].is-medium.is-rounded+label::after,.switch[type="checkbox"].is-medium.is-rounded+label:after {
    border-radius: 50%
}

.switch[type="checkbox"].is-large+label {
    position: relative;
    display: initial;
    font-size: 1.5rem;
    line-height: initial;
    padding-left: 5rem;
    padding-top: .2rem;
    cursor: pointer
}

.switch[type="checkbox"].is-large+label::before,.switch[type="checkbox"].is-large+label:before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 4.5rem;
    height: 2.25rem;
    border: .1rem solid transparent;
    border-radius: 4px;
    background: #b5b5b5;
    content: ""
}

.switch[type="checkbox"].is-large+label::after,.switch[type="checkbox"].is-large+label:after {
    display: block;
    position: absolute;
    top: .25rem;
    left: .25rem;
    width: 1.75rem;
    height: 1.75rem;
    transform: translate3d(0, 0, 0);
    border-radius: 4px;
    background: #fff;
    transition: all .25s ease-out;
    content: ""
}

.switch[type="checkbox"].is-large.is-rtl+label {
    padding-left: 0;
    padding-right: 5rem
}

.switch[type="checkbox"].is-large.is-rtl+label::before,.switch[type="checkbox"].is-large.is-rtl+label:before {
    left: auto;
    right: 0
}

.switch[type="checkbox"].is-large.is-rtl+label::after,.switch[type="checkbox"].is-large.is-rtl+label:after {
    left: auto;
    right: .25rem
}

.switch[type="checkbox"].is-large:checked+label::before,.switch[type="checkbox"].is-large:checked+label:before {
    background: #00d1b2
}

.switch[type="checkbox"].is-large:checked+label::after {
    left: 2.375rem
}

.switch[type="checkbox"].is-large:checked.is-rtl+label::after,.switch[type="checkbox"].is-large:checked.is-rtl+label:after {
    left: auto;
    right: 2.375rem
}

.switch[type="checkbox"].is-large.is-outlined+label::before,.switch[type="checkbox"].is-large.is-outlined+label:before {
    background-color: transparent;
    border-color: #b5b5b5
}

.switch[type="checkbox"].is-large.is-outlined+label::after,.switch[type="checkbox"].is-large.is-outlined+label:after {
    background: #b5b5b5
}

.switch[type="checkbox"].is-large.is-outlined:checked+label::before,.switch[type="checkbox"].is-large.is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #00d1b2
}

.switch[type="checkbox"].is-large.is-outlined:checked+label::after,.switch[type="checkbox"].is-large.is-outlined:checked+label:after {
    background: #00d1b2
}

.switch[type="checkbox"].is-large.is-thin+label::before,.switch[type="checkbox"].is-large.is-thin+label:before {
    top: .8181818182rem;
    height: .5625rem
}

.switch[type="checkbox"].is-large.is-thin+label::after,.switch[type="checkbox"].is-large.is-thin+label:after {
    box-shadow: 0px 0px 3px #7a7a7a
}

.switch[type="checkbox"].is-large.is-rounded+label::before,.switch[type="checkbox"].is-large.is-rounded+label:before {
    border-radius: 24px
}

.switch[type="checkbox"].is-large.is-rounded+label::after,.switch[type="checkbox"].is-large.is-rounded+label:after {
    border-radius: 50%
}

.switch[type="checkbox"].is-white:checked+label::before,.switch[type="checkbox"].is-white:checked+label:before {
    background: #fff
}

.switch[type="checkbox"].is-white.is-outlined:checked+label::before,.switch[type="checkbox"].is-white.is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #fff !important
}

.switch[type="checkbox"].is-white.is-outlined:checked+label::after,.switch[type="checkbox"].is-white.is-outlined:checked+label:after {
    background: #fff
}

.switch[type="checkbox"].is-white.is-thin.is-outlined+label::after,.switch[type="checkbox"].is-white.is-thin.is-outlined+label:after {
    box-shadow: none
}

.switch[type="checkbox"].is-unchecked-white+label::before,.switch[type="checkbox"].is-unchecked-white+label:before {
    background: #fff
}

.switch[type="checkbox"].is-unchecked-white.is-outlined+label::before,.switch[type="checkbox"].is-unchecked-white.is-outlined+label:before {
    background-color: transparent;
    border-color: #fff !important
}

.switch[type="checkbox"].is-unchecked-white.is-outlined+label::after,.switch[type="checkbox"].is-unchecked-white.is-outlined+label:after {
    background: #fff
}

.switch[type="checkbox"].is-black:checked+label::before,.switch[type="checkbox"].is-black:checked+label:before {
    background: #0a0a0a
}

.switch[type="checkbox"].is-black.is-outlined:checked+label::before,.switch[type="checkbox"].is-black.is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #0a0a0a !important
}

.switch[type="checkbox"].is-black.is-outlined:checked+label::after,.switch[type="checkbox"].is-black.is-outlined:checked+label:after {
    background: #0a0a0a
}

.switch[type="checkbox"].is-black.is-thin.is-outlined+label::after,.switch[type="checkbox"].is-black.is-thin.is-outlined+label:after {
    box-shadow: none
}

.switch[type="checkbox"].is-unchecked-black+label::before,.switch[type="checkbox"].is-unchecked-black+label:before {
    background: #0a0a0a
}

.switch[type="checkbox"].is-unchecked-black.is-outlined+label::before,.switch[type="checkbox"].is-unchecked-black.is-outlined+label:before {
    background-color: transparent;
    border-color: #0a0a0a !important
}

.switch[type="checkbox"].is-unchecked-black.is-outlined+label::after,.switch[type="checkbox"].is-unchecked-black.is-outlined+label:after {
    background: #0a0a0a
}

.switch[type="checkbox"].is-light:checked+label::before,.switch[type="checkbox"].is-light:checked+label:before {
    background: #f5f5f5
}

.switch[type="checkbox"].is-light.is-outlined:checked+label::before,.switch[type="checkbox"].is-light.is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #f5f5f5 !important
}

.switch[type="checkbox"].is-light.is-outlined:checked+label::after,.switch[type="checkbox"].is-light.is-outlined:checked+label:after {
    background: #f5f5f5
}

.switch[type="checkbox"].is-light.is-thin.is-outlined+label::after,.switch[type="checkbox"].is-light.is-thin.is-outlined+label:after {
    box-shadow: none
}

.switch[type="checkbox"].is-unchecked-light+label::before,.switch[type="checkbox"].is-unchecked-light+label:before {
    background: #f5f5f5
}

.switch[type="checkbox"].is-unchecked-light.is-outlined+label::before,.switch[type="checkbox"].is-unchecked-light.is-outlined+label:before {
    background-color: transparent;
    border-color: #f5f5f5 !important
}

.switch[type="checkbox"].is-unchecked-light.is-outlined+label::after,.switch[type="checkbox"].is-unchecked-light.is-outlined+label:after {
    background: #f5f5f5
}

.switch[type="checkbox"].is-dark:checked+label::before,.switch[type="checkbox"].is-dark:checked+label:before {
    background: #363636
}

.switch[type="checkbox"].is-dark.is-outlined:checked+label::before,.switch[type="checkbox"].is-dark.is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #363636 !important
}

.switch[type="checkbox"].is-dark.is-outlined:checked+label::after,.switch[type="checkbox"].is-dark.is-outlined:checked+label:after {
    background: #363636
}

.switch[type="checkbox"].is-dark.is-thin.is-outlined+label::after,.switch[type="checkbox"].is-dark.is-thin.is-outlined+label:after {
    box-shadow: none
}

.switch[type="checkbox"].is-unchecked-dark+label::before,.switch[type="checkbox"].is-unchecked-dark+label:before {
    background: #363636
}

.switch[type="checkbox"].is-unchecked-dark.is-outlined+label::before,.switch[type="checkbox"].is-unchecked-dark.is-outlined+label:before {
    background-color: transparent;
    border-color: #363636 !important
}

.switch[type="checkbox"].is-unchecked-dark.is-outlined+label::after,.switch[type="checkbox"].is-unchecked-dark.is-outlined+label:after {
    background: #363636
}

.switch[type="checkbox"].is-primary:checked+label::before,.switch[type="checkbox"].is-primary:checked+label:before {
    background: #00d1b2
}

.switch[type="checkbox"].is-primary.is-outlined:checked+label::before,.switch[type="checkbox"].is-primary.is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #00d1b2 !important
}

.switch[type="checkbox"].is-primary.is-outlined:checked+label::after,.switch[type="checkbox"].is-primary.is-outlined:checked+label:after {
    background: #00d1b2
}

.switch[type="checkbox"].is-primary.is-thin.is-outlined+label::after,.switch[type="checkbox"].is-primary.is-thin.is-outlined+label:after {
    box-shadow: none
}

.switch[type="checkbox"].is-unchecked-primary+label::before,.switch[type="checkbox"].is-unchecked-primary+label:before {
    background: #00d1b2
}

.switch[type="checkbox"].is-unchecked-primary.is-outlined+label::before,.switch[type="checkbox"].is-unchecked-primary.is-outlined+label:before {
    background-color: transparent;
    border-color: #00d1b2 !important
}

.switch[type="checkbox"].is-unchecked-primary.is-outlined+label::after,.switch[type="checkbox"].is-unchecked-primary.is-outlined+label:after {
    background: #00d1b2
}

.switch[type="checkbox"].is-link:checked+label::before,.switch[type="checkbox"].is-link:checked+label:before {
    background: #3273dc
}

.switch[type="checkbox"].is-link.is-outlined:checked+label::before,.switch[type="checkbox"].is-link.is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #3273dc !important
}

.switch[type="checkbox"].is-link.is-outlined:checked+label::after,.switch[type="checkbox"].is-link.is-outlined:checked+label:after {
    background: #3273dc
}

.switch[type="checkbox"].is-link.is-thin.is-outlined+label::after,.switch[type="checkbox"].is-link.is-thin.is-outlined+label:after {
    box-shadow: none
}

.switch[type="checkbox"].is-unchecked-link+label::before,.switch[type="checkbox"].is-unchecked-link+label:before {
    background: #3273dc
}

.switch[type="checkbox"].is-unchecked-link.is-outlined+label::before,.switch[type="checkbox"].is-unchecked-link.is-outlined+label:before {
    background-color: transparent;
    border-color: #3273dc !important
}

.switch[type="checkbox"].is-unchecked-link.is-outlined+label::after,.switch[type="checkbox"].is-unchecked-link.is-outlined+label:after {
    background: #3273dc
}

.switch[type="checkbox"].is-info:checked+label::before,.switch[type="checkbox"].is-info:checked+label:before {
    background: #209cee
}

.switch[type="checkbox"].is-info.is-outlined:checked+label::before,.switch[type="checkbox"].is-info.is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #209cee !important
}

.switch[type="checkbox"].is-info.is-outlined:checked+label::after,.switch[type="checkbox"].is-info.is-outlined:checked+label:after {
    background: #209cee
}

.switch[type="checkbox"].is-info.is-thin.is-outlined+label::after,.switch[type="checkbox"].is-info.is-thin.is-outlined+label:after {
    box-shadow: none
}

.switch[type="checkbox"].is-unchecked-info+label::before,.switch[type="checkbox"].is-unchecked-info+label:before {
    background: #209cee
}

.switch[type="checkbox"].is-unchecked-info.is-outlined+label::before,.switch[type="checkbox"].is-unchecked-info.is-outlined+label:before {
    background-color: transparent;
    border-color: #209cee !important
}

.switch[type="checkbox"].is-unchecked-info.is-outlined+label::after,.switch[type="checkbox"].is-unchecked-info.is-outlined+label:after {
    background: #209cee
}

.switch[type="checkbox"].is-success:checked+label::before,.switch[type="checkbox"].is-success:checked+label:before {
    background: #23d160
}

.switch[type="checkbox"].is-success.is-outlined:checked+label::before,.switch[type="checkbox"].is-success.is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #23d160 !important
}

.switch[type="checkbox"].is-success.is-outlined:checked+label::after,.switch[type="checkbox"].is-success.is-outlined:checked+label:after {
    background: #23d160
}

.switch[type="checkbox"].is-success.is-thin.is-outlined+label::after,.switch[type="checkbox"].is-success.is-thin.is-outlined+label:after {
    box-shadow: none
}

.switch[type="checkbox"].is-unchecked-success+label::before,.switch[type="checkbox"].is-unchecked-success+label:before {
    background: #23d160
}

.switch[type="checkbox"].is-unchecked-success.is-outlined+label::before,.switch[type="checkbox"].is-unchecked-success.is-outlined+label:before {
    background-color: transparent;
    border-color: #23d160 !important
}

.switch[type="checkbox"].is-unchecked-success.is-outlined+label::after,.switch[type="checkbox"].is-unchecked-success.is-outlined+label:after {
    background: #23d160
}

.switch[type="checkbox"].is-warning:checked+label::before,.switch[type="checkbox"].is-warning:checked+label:before {
    background: #ffdd57
}

.switch[type="checkbox"].is-warning.is-outlined:checked+label::before,.switch[type="checkbox"].is-warning.is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #ffdd57 !important
}

.switch[type="checkbox"].is-warning.is-outlined:checked+label::after,.switch[type="checkbox"].is-warning.is-outlined:checked+label:after {
    background: #ffdd57
}

.switch[type="checkbox"].is-warning.is-thin.is-outlined+label::after,.switch[type="checkbox"].is-warning.is-thin.is-outlined+label:after {
    box-shadow: none
}

.switch[type="checkbox"].is-unchecked-warning+label::before,.switch[type="checkbox"].is-unchecked-warning+label:before {
    background: #ffdd57
}

.switch[type="checkbox"].is-unchecked-warning.is-outlined+label::before,.switch[type="checkbox"].is-unchecked-warning.is-outlined+label:before {
    background-color: transparent;
    border-color: #ffdd57 !important
}

.switch[type="checkbox"].is-unchecked-warning.is-outlined+label::after,.switch[type="checkbox"].is-unchecked-warning.is-outlined+label:after {
    background: #ffdd57
}

.switch[type="checkbox"].is-danger:checked+label::before,.switch[type="checkbox"].is-danger:checked+label:before {
    background: #ff3860
}

.switch[type="checkbox"].is-danger.is-outlined:checked+label::before,.switch[type="checkbox"].is-danger.is-outlined:checked+label:before {
    background-color: transparent;
    border-color: #ff3860 !important
}

.switch[type="checkbox"].is-danger.is-outlined:checked+label::after,.switch[type="checkbox"].is-danger.is-outlined:checked+label:after {
    background: #ff3860
}

.switch[type="checkbox"].is-danger.is-thin.is-outlined+label::after,.switch[type="checkbox"].is-danger.is-thin.is-outlined+label:after {
    box-shadow: none
}

.switch[type="checkbox"].is-unchecked-danger+label::before,.switch[type="checkbox"].is-unchecked-danger+label:before {
    background: #ff3860
}

.switch[type="checkbox"].is-unchecked-danger.is-outlined+label::before,.switch[type="checkbox"].is-unchecked-danger.is-outlined+label:before {
    background-color: transparent;
    border-color: #ff3860 !important
}

.switch[type="checkbox"].is-unchecked-danger.is-outlined+label::after,.switch[type="checkbox"].is-unchecked-danger.is-outlined+label:after {
    background: #ff3860
}