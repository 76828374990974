.mobileToolMenu{
    position: fixed;
    right: -42px;
    top: 51px;
    z-index: 99;
    transition: right .5s cubic-bezier(0.22, 0.61, 0.36, 1);
    width: 43px;
    background: var(--toolbar-bg);
    padding: 5px;
    border-radius: 4px;
    display: block;

}

.mobileToolMenu.openMenu{
    right: 0px;
}
.mobileToolMenu .compact-picker{
    right: 43px;
    position: fixed;
}
.menuHandler{
    display: inline-block;
    width: 100%;
}
.openMenuHandler{
    position: fixed;
    z-index: 99;
    width: 40px;
    color: var(--text-color);
    right: 0px;
    background: var(--toolbar-bg);
    padding-left: 11px;
    padding-right: 2px;
    padding-top: 5px;
    padding-bottom: 6px;
    top: 50px;
    border-left: 1px solid var(--border-light-color);
    border-top: 1px solid var(--border-light-color);
    border-bottom: 1px solid var(--border-light-color);
    border-radius: 3px;
    display: block;
}
.mobileToolMenu .button{
    height: 35px;
    width: 35px;
    border-radius: 3px;
    border-color: transparent;
    background-color: transparent;
    color: var(--text-color);
    padding: 0;
}

.search-close-icon,
.mobileToolMenu .button img,
.smallMenuClose{
    filter: var(--icons-filter);
}
.menu-li-ul>li:not(.menu-li-ul>li:last-child){
    margin-bottom: 2px;
    text-align: center;
}
.menu-li-ul>li:not(.menu-li-ul>li:first-child){
    margin-top: 12px;
    text-align: center;
}
.smallMenuClose:not(.notransform){
    transform: rotate(180deg);
}

.mobileToolMenu_inner{
    position: relative;
}
.has-addons.smallscroll{

    overflow-y: scroll;
}
.scroll-bar-icons {
    position: absolute;
    width: 100%;
    background: var(--toolbar-bg);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: center;
    align-items: center;

}
.topscroll{
    top:28px;
}
.top-menu-closer{
    top:0;
}
.bottomscroll{
    bottom:0;

}
.smallscroll{
    padding-top: 14px;
    padding-bottom: 14px;
}
.mobile-menu-top-margin{
    margin-top: 32px !important;
}

/* @media screen and (max-device-width: 768px) and (orientation : landscape){
    .openMenuHandler,
    .mobileToolMenu
    {
        display: block !important;
    }
}
@media screen and (max-device-width: 479px) and (orientation : portrait){
    .openMenuHandler,
    .mobileToolMenu
    {
        display: block !important;
    }
} */
