@media screen and (max-width: 768px) {
    .is-loading-anim {
        margin: auto;
        right: 3vw;
        top: 0px;
        position: absolute;
    }


    .text-tools {
        height: 32px;
        width: 248px;
    }

    .text-tools .button {
        height: 30px;
        width: 30px;
    }

    .text-size select {
        height: 30px;
    }

    .text-size .select:not(.is-multiple) {
        height: 30px;
    }

    .text-size .icon {
        top: 9px;
    }

    .text-font-family select {
        height: 30px;
    }

    .text-font-family .select:not(.is-multiple) {
        height: 30px;
    }

    .text-font-family .icon {
        top: 9px;
    }

    .toolbar-wrapper {
        /* bottom: 35px; */
        bottom: 62vh;
        left: calc(87% - 308px);
    }
    .toolbar-wrapper-alternative {
        bottom: 62vh;
        left: calc(87% - 378px);
    }
    .toolbar-init {
        left: calc(87% - 178px);
    }

    .toolbar-wrapper-change-left {
        left: calc(70% - 303px);
    }


    .main-navbar {
        height: 40px;
        width: auto;
    }



    /* LOGIN FORM */
    .login-form {
        width: 540px;
        height: 400px;
        border-radius: 16px;
    }
    .login-form-sign-up {
        width: 540px;
        border-radius: 16px;
        height: 580px;
      }


    .lf-left {
        position: relative;
    }

    .lf-left-title {
        font-size: 24px;
        font-weight: 300;
        /* color: #fff; */
        margin-top: 50px;

    }
    .lf-left-sign-up {
        position: relative;
        height: 600px;
        width: 320px;
    }

    .lf-right-sign-up {
        height: 600px;
        width: 340px;
    }

    .mobile-toolbar {
        /* position: fixed; */
        /* bottom: 0px; */
        /* left:0px; */
        /* margin: auto 0 !important; */
        padding-left: 0px;
    }

    .welcome-logo {
        /* width: 50%; */
        padding-bottom: 35px;
        padding-top: 0px;
        /* padding-left: 10px; */
        margin: auto;

        max-width: 70%;
    }

    .welcome-logo-company {
        /* width: 50%; */
        padding-bottom: 15px;
        padding-top: 20px;
        /* padding-left: 10px; */
        margin: auto;

        max-width: 70%;
    }

    .bookmark {
        width: 5vw !important;
        height: 5vW !important;
        padding: .5vw !important;
    }

    .activity-options {
        width: 260px !important;
        margin-left: 162px;
    }
    .pdf-page-list {
        padding: 10px 20px 0;
        cursor: pointer;
        /* 0 24px 0  */
        margin: 0 !important;
      }


}